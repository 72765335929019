<template>
	<div class="forgot-password">
		<template>
			<form class="login-form">
				<v-container>
					<v-row class="flex-column" style="border: gray solid 1px">
						<v-col cols="12" sm="12">
							<p>
								Passwords must have
								<strong>at least 6 characters.</strong>
							</p>
							<p>
								Passwords must have
								<strong>at least one non alphanumeric character (i.e. !~@#$&*).</strong>
							</p>
							<p>
								Passwords must have
								<strong>at least one digit ('0'-'9').</strong>
							</p>
							<p>
								Passwords must have
								<strong>at least one uppercase ('A'-'Z').</strong>
							</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12">
							<label for="j_password">Enter New Password</label>
							<div class="input-container">
								<input
									:type="inputType"
									id="j_password"
									name="j_password"
									class="text"
									style="cursor: auto; max-width: 255px"
									v-model="newPassword.password"
									@keyup.enter="resetPassword"
								/>
								<button type="button" class="login-form-btn" @click="showHide = !showHide">
									<v-icon v-if="!showHide" class="view-control">mdi-eye</v-icon>
									<v-icon v-else class="view-control">mdi-eye-off</v-icon>
								</button>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12">
							<label for="j_passwordVerify">Confirm New Password</label>
							<input
								:type="inputType"
								id="j_passwordVerify"
								name="j_passwordVerify"
								class="text"
								style="cursor: auto; max-width: 255px"
								v-model="newPassword.passwordVerify"
								@keyup.enter="resetPassword"
							/>
							<div v-show="formError" class="form-error">
								<ul v-for="errorMessage in errorMessages" :key="errorMessage">
									<li>
										<template>
											<span class="error-message">{{ errorMessage }}</span>
										</template>
									</li>
								</ul>
							</div>
						</v-col>
					</v-row>
				</v-container>

				<button type="button" class="login-form-btn ml-4" @click="resetPassword">Reset</button>
			</form>
		</template>

		<Modal v-if="success" title="Request Completed" @close="redirect">
			<template v-slot:content>
				<span>Your password has been reset successfully.</span>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
	name: "ResetPassword",
	components: {
		Modal,
	},
	data() {
		return {
			newPassword: {
				password: "",
				passwordVerify: "",
			},
			formError: false,
			errorMessages: [],
			email: this.$route.query.email,
			token: this.$route.query.token,
			showHide: false,
			success: false,
		};
	},
	methods: {
		async resetPassword() {
			this.validate();

			try {
				if (!this.formError) {
					let resp = await this.$http.post(`/api/account/reset-user-password`, {
						username: this.email,
						token: this.token,
						newPass: this.newPassword.password,
					});

					console.log(resp);

					if (resp) {
						this.success = true;
					}
				}
			} catch (error) {
				this.formError = true;
				this.errorMessages.push(error.response.data);
			}
		},
		validate() {
			this.formError = false;

			if (!this.newPassword.password) {
				this.formError = true;
				this.errorMessages.push("Please fill out your new password.");
			}

			if (!this.newPassword.passwordVerify) {
				this.formError = true;
				this.errorMessages.push("Please confirm your new password in the input above.");
			}

			if (this.newPassword.password !== this.newPassword.passwordVerify) {
				this.formError = true;
				this.errorMessages.push("The passwords provided do not match. Please verify.");
			}
		},
		redirect() {
			this.success = false;
			this.$router.push("/login");
		},
	},
	computed: {
		inputType() {
			return this.showHide ? "text" : "password";
		},
	},
	mounted() {
		if (!this.token) {
			this.formError = true;
			this.errorMessages.push(
				"Reset password token is not present. Please contact Chemformation for support."
			);
		}

		if (!this.email) {
			this.formError = true;
			this.errorMessages.push(
				"User email is not present in the request. Please contact Chemformation for support."
			);
		}
	},
};
</script>

<style lang="scss" scoped>
.forgot-password {
	position: relative;
	display: flex;
	flex-flow: row wrap;

	.login-form {
		.input-container {
			display: flex;
		}

		input {
			display: block;
			clear: both;
			margin: 10px;
			border: 1px solid #a6c9e2;
			border-radius: 5px;
			background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
			color: #222;
			width: calc(100% - 20px);
		}

		&-btn {
			display: inline-block;
			margin: 10px;
			padding: 4px 11px;
			border: 1px solid #c5dbec;
			border-radius: 5px;
			background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			color: #2e6e9e;
			font-size: 1.1rem;

			&-link {
				color: #2e6e9e;
				font-size: 1.1rem;
				text-decoration: underline;
			}
		}

		.form-error {
			.error-message {
				color: red;
				font-weight: bold;
			}
		}

		.view-control {
			font-size: 15px;
		}
	}
}

.modal-overlay {
	height: auto;
}
</style>
